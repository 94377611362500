import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Card, CardContent, CardHeader, IconButton, Tooltip, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import aibotsData from "../../data/aibots.json";

// Dynamically import all bot components
const botComponents = {};
const requireBot = require.context('../../bots', false, /\.js$/);
requireBot.keys().forEach((file) => {
    const botName = file.match(/\.\/(\w+)_botlink\.js$/)[1];
    botComponents[botName.toLowerCase()] = requireBot(file).default;
});

const AvatarLink = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [avatars, setAvatars] = useState([]);

    useEffect(() => {
        // Load avatar data from JSON
        setAvatars(aibotsData.personalities);
    }, []);

    const handleCopyEmbedCode = (embedCode) => {
        navigator.clipboard.writeText(embedCode).then(
            () => alert("Embed code copied to clipboard!"),
            (err) => console.error("Could not copy text: ", err)
        );
    };

    return (
        <Box sx={{ padding: 4, backgroundColor: colors.primary[600] }}>
            <Typography variant="h3" color="white" gutterBottom>
                Avatar Links
            </Typography>
            <Typography variant="body1" color="white" paragraph>
                Explore and embed avatars. Each avatar is designed to enhance interaction based on the page's context.
            </Typography>

            {avatars.map((avatar) => {
                const scriptSrc = `http://pos-stu.swancreate.com/js/${avatar.id}_botlink.js`; // Replace with actual public link
                const embedCode = `<div id="${avatar.id}-avatar"></div>\n<script src="${scriptSrc}" async></script>`;

                return (
                    <Card key={avatar.id} sx={{ marginBottom: 4, backgroundColor: colors.primary[700] }}>
                        <CardHeader
                            avatar={<Avatar src={avatar.image} alt={avatar.name} sx={{ width: 56, height: 56 }} />}
                            action={
                                <Tooltip title="More Info">
                                    <IconButton aria-label="info">
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            title={<Typography variant="h5" color="white">{avatar.name}</Typography>}
                            subheader={<Typography variant="subtitle1" color={colors.grey[100]}>{avatar.blurb}</Typography>}
                        />
                        <CardContent>
                            <Typography variant="body2" color="white" sx={{ marginBottom: 2 }}>
                                {avatar.bio}
                            </Typography>
                            <Box sx={{ position: 'relative', marginTop: 2 }}>
                                <TextField
                                    value={embedCode}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{ bgcolor: colors.primary[800], color: 'white' }}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                    <Tooltip title="Copy Embed Code">
                                        <IconButton onClick={() => handleCopyEmbedCode(embedCode)}>
                                            <FileCopyIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant="body2" color="white" sx={{ marginLeft: 1 }}>
                                        Copy Avatar Embed Code
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                );
            })}
        </Box>
    );
};

export default AvatarLink;
