import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Avatar, Select, MenuItem, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import config from '../../data/config.json';

const EmailSenderDashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [templateList, setTemplateList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [templateContent, setTemplateContent] = useState("");
    const [prompt, setPrompt] = useState("");
    const [subject, setSubject] = useState("");
    const [logoLink, setLogoLink] = useState("");
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [isHtmlView, setIsHtmlView] = useState(false);
    const [consoleMessages, setConsoleMessages] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [testEmail, setTestEmail] = useState("");

    // Fetch templates from JSON
    useEffect(() => {
        fetch('/assets/EmailTemplates/emailTemplates.json')
            .then(response => response.json())
            .then(data => setTemplateList(data.sort((a, b) => a.name.localeCompare(b.name))))
            .catch(error => console.error("Error loading template list:", error));
    }, []);

    // Fetch template content
    const handleTemplateSelection = () => {
        if (selectedTemplate) {
            fetch(`/assets/EmailTemplates/${selectedTemplate}`)
                .then(response => response.text())
                .then(content => setResponse(content))
                .catch(error => console.error("Error loading template:", error));
        }
    };

    // Transfer content to ChatGPT
    const handleSendTemplateToChatPrompt = () => {
        setPrompt(`${response}\n\n\n`);
        setResponse(""); // Clear HTML viewer
    };

    // Fetch student data
    useEffect(() => {
        axios.get('https://api.swancreate.com/api/pos/students')
            .then(response => setStudentData(response.data))
            .catch(error => console.error("Error fetching student data:", error));
    }, []);

    return (
        <Box>
            <Header title="Email Configuration" subtitle="Design and Send Customized Emails" />
            <Box m={4} p={3} bgcolor={colors.primary[800]} color="#FFFFFF" borderRadius={2} boxShadow={3}>
                <Typography variant="h4" gutterBottom>
                    📧 How to Use the Email Sender Dashboard
                </Typography>
                <ul>
                    <li>📝 Enter email content prompt and details.</li>
                    <li>📩 Click "Generate Email Template" to get a draft from ChatGPT.</li>
                    <li>🔍 Filter recipients using the data table below.</li>
                    <li>🚀 Choose "Send Campaign" or test email options.</li>
                    <li>📂 Use file upload options to import templates.</li>
                </ul>
            </Box>

            <Box p={4} display="flex" flexDirection="row" gap={2} bgcolor={colors.primary[700]}>
                <Box display="flex" flexDirection="column" width="40%" gap={2}>
                    <Box display="flex" alignItems="center">
                        <Select
                            value={selectedTemplate}
                            onChange={(event) => setSelectedTemplate(event.target.value)}
                            displayEmpty
                            variant="filled"
                            sx={{ bgcolor: colors.primary[800], color: "white", borderRadius: 1, flex: 1 }}
                        >
                            <MenuItem value="" disabled>Select a template</MenuItem>
                            {templateList.map(template => (
                                <MenuItem key={template.filename} value={template.filename}>
                                    {template.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <IconButton color="primary" onClick={handleSendTemplateToChatPrompt} sx={{ ml: 1 }}>
                            <Avatar src="/assets/jefferson.jpg" alt="Jefferson" />
                        </IconButton>
                    </Box>

                    <Button onClick={handleTemplateSelection} variant="contained" color="primary">
                        Load Template into Viewer
                    </Button>

                    <TextField fullWidth label="Email Content Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} multiline rows={4} variant="filled" sx={{ bgcolor: colors.primary[800], color: "white", borderRadius: 1 }} InputLabelProps={{ style: { color: 'white' } }} />
                    <TextField fullWidth label="Email Subject" value={subject} onChange={(e) => setSubject(e.target.value)} variant="filled" sx={{ bgcolor: colors.primary[800], color: "white", borderRadius: 1 }} InputLabelProps={{ style: { color: 'white' } }} />
                    <TextField fullWidth label="Logo Link" value={logoLink} onChange={(e) => setLogoLink(e.target.value)} variant="filled" sx={{ bgcolor: colors.primary[800], color: "white", borderRadius: 1 }} InputLabelProps={{ style: { color: 'white' } }} />

                    <Button variant="contained" color="primary">
                        Upload for ChatGPT Edit
                    </Button>

                    <Button variant="contained" color="primary">
                        Upload Existing Template
                    </Button>
                </Box>

                {/* Right Column */}
                <Box width="60%">
                    <Typography variant="h5" mb={2} style={{ fontWeight: 'bold', color: 'white' }}>ChatGPT Response</Typography>
                    <Button variant="contained" color="primary" onClick={() => setIsHtmlView(!isHtmlView)} sx={{ mb: 2 }}>
                        {isHtmlView ? "View as Text" : "View as HTML"}
                    </Button>
                    <Box sx={{ fontFamily: "monospace", padding: "20px", minHeight: "400px", borderRadius: "5px", overflowY: "auto", backgroundColor: "#1E1E1E", color: isHtmlView ? "#000" : "#FFD700" }}>
                        {isHtmlView ? <div dangerouslySetInnerHTML={{ __html: response || "Awaiting response..." }} /> : <Typography style={{ whiteSpace: "pre-wrap" }}>{response || "Awaiting response..."}</Typography>}
                    </Box>
                </Box>
            </Box>

            <Box mt={4} p={4} height="60vh" sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700] },
                "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
            }}>
                <DataGrid rows={studentData} columns={[
                    { field: "SchoolName", headerName: "School Name", flex: 1 },
                    { field: "StudentId", headerName: "Student ID", flex: 1 },
                    { field: "FirstName", headerName: "First Name", flex: 1 },
                    { field: "LastName", headerName: "Last Name", flex: 1 },
                    { field: "GuardianEmail", headerName: "Guardian Email", flex: 1 },
                ]} components={{ Toolbar: GridToolbar }} getRowId={(row) => row.StudentId} pageSize={10} />
            </Box>
        </Box>
    );
};

export default EmailSenderDashboard;
