import React, { useState, useEffect } from 'react';
import { Box, Typography, MenuItem, Select, Avatar, Slider, useMediaQuery, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import ChatConsole from '../../components/ChatConsole';
import botData from '../../data/aibots.json';

const PageContainer = styled(Box)(({ theme }) => ({
    maxWidth: '800px',
    margin: '0 auto',
    paddingTop: '50px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#1A202C',
    borderRadius: '8px',
}));

const JeffersonAvatarPageDL = () => {
    const [people, setPeople] = useState([]);
    const [selectedPersonality, setSelectedPersonality] = useState(null);
    const [attributes, setAttributes] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setPeople(botData.personalities);
        if (botData.personalities.length > 0) {
            const firstPersonality = botData.personalities[0];
            setSelectedPersonality(firstPersonality);
            setAttributes({
                intelligence: firstPersonality.intelligence || 0,
                wit: firstPersonality.wit || 0,
                attitude: firstPersonality.attitude || 0,
                creativity: firstPersonality.creativity || 0,
                empathy: firstPersonality.empathy || 0,
                humor: firstPersonality.humor || 0,
                resilience: firstPersonality.resilience || 0,
                charisma: 70, // Additional attribute to balance the grid layout
            });
        }
    }, []);

    const handlePersonalityChange = (event) => {
        const selected = people.find((person) => person.id === event.target.value);
        setSelectedPersonality(selected);
        setAttributes({
            intelligence: selected.intelligence || 0,
            wit: selected.wit || 0,
            attitude: selected.attitude || 0,
            creativity: selected.creativity || 0,
            empathy: selected.empathy || 0,
            humor: selected.humor || 0,
            resilience: selected.resilience || 0,
            charisma: 70, // Add a default charisma value
        });
    };

    const handleSliderChange = (attribute) => (event, newValue) => {
        setAttributes((prevAttributes) => ({
            ...prevAttributes,
            [attribute]: newValue,
        }));
    };

    const getColorFromValue = (value) => {
        const red = value < 50 ? 255 : Math.floor(255 - (value - 50) * 5.1);
        const green = value > 50 ? 255 : Math.floor(value * 5.1);
        return `rgb(${red}, ${green}, 0)`;
    };

    const personalityAttributes = [
        { name: 'Intelligence', key: 'intelligence' },
        { name: 'Wit', key: 'wit' },
        { name: 'Attitude', key: 'attitude' },
        { name: 'Creativity', key: 'creativity' },
        { name: 'Empathy', key: 'empathy' },
        { name: 'Humor', key: 'humor' },
        { name: 'Resilience', key: 'resilience' },
        { name: 'Charisma', key: 'charisma' }, // Additional attribute for even layout
    ];

    if (!selectedPersonality) return <div>Loading...</div>;

    return (
        <PageContainer>
            {/* Personality Details */}
            <Box sx={{
                padding: '20px',
                backgroundColor: '#2B2B2B',
                color: '#E2E8F0',
                borderRadius: '8px',
                textAlign: 'center',
                marginBottom: '20px'
            }}
            >
                <Select
                    fullWidth
                    value={selectedPersonality.id}
                    onChange={handlePersonalityChange}
                    sx={{
                        marginBottom: '16px',
                        fontSize: isMobile ? '0.9rem' : '1rem',
                        backgroundColor: '#3C3F41',
                        color: '#E2E8F0',
                    }}
                >
                    {people.map((person) => (
                        <MenuItem key={person.id} value={person.id} sx={{ color: '#E2E8F0' }}>
                            {person.name}
                        </MenuItem>
                    ))}
                </Select>

                <Avatar
                    src={selectedPersonality.image}
                    alt={selectedPersonality.name}
                    sx={{
                        width: isMobile ? 60 : 80,
                        height: isMobile ? 60 : 80,
                        margin: '0 auto',
                        mb: 2,
                    }}
                />
                <Typography variant="h6" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                    {selectedPersonality.name}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
                    {selectedPersonality.title}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                    {selectedPersonality.lifespan || selectedPersonality.dob}
                </Typography>

                {/* Personality Attributes Container */}
                <Box sx={{ mt: 3, p: 2, backgroundColor: '#353B42', borderRadius: '8px' }}>
                    <Grid container spacing={2}>
                        {personalityAttributes.map((attr) => (
                            <Grid item xs={6} sm={3} key={attr.key}>
                                <Typography gutterBottom variant="body2" align="center">{attr.name}</Typography>
                                <Slider
                                    value={attributes[attr.key]}
                                    min={0}
                                    max={100}
                                    onChange={handleSliderChange(attr.key)}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        color: getColorFromValue(attributes[attr.key]),
                                        '& .MuiSlider-thumb': {
                                            width: 12,
                                            height: 12,
                                            backgroundColor: getColorFromValue(attributes[attr.key]),
                                        },
                                        '& .MuiSlider-track': {
                                            backgroundColor: getColorFromValue(attributes[attr.key]),
                                        },
                                        '& .MuiSlider-rail': {
                                            opacity: 0.3,
                                        },
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Famous Quote */}
                <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 'bold' }}>Favorite Quote:</Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{selectedPersonality.favoriteQuote}</Typography>

                {/* Additional Attributes */}
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>Vernacular:</Typography>
                <Typography variant="body2">{selectedPersonality.vernacular}</Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>Time Period:</Typography>
                <Typography variant="body2">{selectedPersonality.timePeriod}</Typography>

                {/* Full Biography */}
                <Box sx={{ mt: 2, textAlign: 'left' }}>
                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>Full Biography:</Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>{selectedPersonality.bio}</Typography>
                </Box>
            </Box>

            {/* Chat Console */}
            <Box sx={{
                backgroundColor: '#202B33',
                padding: '20px',
                borderRadius: '8px',
                color: '#FFFFFF',
            }}
            >
                <ChatConsole
                    person={selectedPersonality}
                    promptStyle={{
                        backgroundColor: '#1E1E1E',
                        color: '#E2E8F0',
                        padding: '10px',
                        borderRadius: '5px'
                    }}
                    responseStyle={{
                        backgroundColor: '#012456',
                        color: '#FFFFFF',
                        padding: '10px',
                        borderRadius: '5px'
                    }}
                />
            </Box>
        </PageContainer>
    );
};

export default JeffersonAvatarPageDL;
