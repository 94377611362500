import React, { useState, useEffect } from "react";
import { Box, Button, TextField, MenuItem, Typography, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Paper } from "@mui/material";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import axios from "axios";
import OpenSeadragon from 'openseadragon';

const InvoiceEntry = () => {
    const [vendors, setVendors] = useState([]);
    const [invoiceCategories, setInvoiceCategories] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState("");
    const [vendorDetails, setVendorDetails] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([{ productID: "", description: "", clientUnitCost: 0, quantity: 1, unitPrice: 0 }]);
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [invoiceData, setInvoiceData] = useState({
        InvoiceNumber: "",
        VendorID: "",
        InvoiceCategoryID: "",
        InvoiceDate: "",
        TotalAmount: "",
        Description: "",
        Status: "",
        DateDue: "",
        AcademicYearId: "",
        SchoolDistrictID: "203E8645-FB4E-4190-AB2E-DCCE87BDF7E0",
    });

    useEffect(() => {
        axios.get('http://localhost:8676/finance/vendors')
            .then(response => setVendors(response.data))
            .catch(error => console.error("Error fetching vendors:", error));

        axios.get('http://localhost:8676/finance/vendor-category')
            .then(response => setInvoiceCategories(response.data))
            .catch(error => console.error("Error fetching invoice categories:", error));
    }, []);

    useEffect(() => {
        if (selectedVendor) {
            axios.get(`http://localhost:8676/finance/vendors/${selectedVendor}`)
                .then(response => setVendorDetails(response.data))
                .catch(error => console.error("Error fetching vendor details:", error));
        }
    }, [selectedVendor]);

    const handleVendorChange = (event) => {
        const vendorID = event.target.value;
        setSelectedVendor(vendorID);
        setInvoiceData({ ...invoiceData, VendorID: vendorID });
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFileType(file.type);
        setFile(URL.createObjectURL(file));

        if (file.type === "image/tiff") {
            OpenSeadragon({
                id: "openseadragon-viewer",
                tileSources: file,
                prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
            });
        }
    };

    const handleAddItem = () => {
        setInvoiceItems([...invoiceItems, { productID: "", description: "", clientUnitCost: 0, quantity: 1, unitPrice: 0 }]);
    };

    const handleRemoveItem = (index) => {
        setInvoiceItems(invoiceItems.filter((_, i) => i !== index));
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...invoiceItems];
        updatedItems[index][field] = value;
        setInvoiceItems(updatedItems);
    };

    const handleProductBlur = (index) => {
        const { productID } = invoiceItems[index];
        if (productID.trim()) {
            axios.get(`http://localhost:8676/finance/vendor-product/${productID}`)
                .then(response => {
                    const { description, Price } = response.data;
                    const updatedItems = [...invoiceItems];
                    updatedItems[index].description = description;
                    updatedItems[index].clientUnitCost = Price;
                    setInvoiceItems(updatedItems);
                })
                .catch(error => console.error("Error fetching product details:", error));
        }
    };

    return (
        <Box m={2} display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2} alignItems="flex-start">
                <Box flex={1} minWidth="300px" padding={2} sx={{ '& .MuiTextField-root': { mb: 1, height: '40px' } }}>
                    <Typography variant="h5" mb={2}>Invoice Details</Typography>
                    <TextField fullWidth label="Invoice Number" value={invoiceData.InvoiceNumber} onChange={(e) => setInvoiceData({ ...invoiceData, InvoiceNumber: e.target.value })} size="small" />
                    <TextField fullWidth select label="Vendor" value={invoiceData.VendorID} onChange={handleVendorChange} size="small">
                        {vendors.map(vendor => (
                            <MenuItem key={vendor.VendorID} value={vendor.VendorID}>{vendor.VendorName}</MenuItem>
                        ))}
                    </TextField>
                    <TextField fullWidth select label="Category" value={invoiceData.InvoiceCategoryID} onChange={(e) => setInvoiceData({ ...invoiceData, InvoiceCategoryID: e.target.value })} size="small">
                        {invoiceCategories.map(category => (
                            <MenuItem key={category.InvoiceCategoryID} value={category.InvoiceCategoryID}>{category.CategoryName}</MenuItem>
                        ))}
                    </TextField>
                    <TextField fullWidth label="Invoice Date" type="date" value={invoiceData.InvoiceDate} onChange={(e) => setInvoiceData({ ...invoiceData, InvoiceDate: e.target.value })} InputLabelProps={{ shrink: true }} size="small" />
                    <TextField fullWidth label="Total Amount" type="number" value={invoiceData.TotalAmount} onChange={(e) => setInvoiceData({ ...invoiceData, TotalAmount: e.target.value })} size="small" />
                    <TextField fullWidth label="Description" multiline rows={2} value={invoiceData.Description} onChange={(e) => setInvoiceData({ ...invoiceData, Description: e.target.value })} size="small" />
                    <TextField fullWidth label="Due Date" type="date" value={invoiceData.DateDue} onChange={(e) => setInvoiceData({ ...invoiceData, DateDue: e.target.value })} InputLabelProps={{ shrink: true }} size="small" />
                    {vendorDetails && (
                        <>
                            <TextField fullWidth label="Address" value={vendorDetails.Address || ""} size="small" />
                            <TextField fullWidth label="Email" value={vendorDetails.PaymentContactEmail || ""} size="small" />
                            <TextField fullWidth label="Phone" value={vendorDetails.PaymentContactPhone || ""} size="small" />
                        </>
                    )}
                </Box>
                <Box flex={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ border: "1px solid white", padding: "10px", minHeight: "500px" }}>
                    <Button variant="contained" component="label">
                        Upload File
                        <input type="file" hidden onChange={handleFileUpload} />
                    </Button>
                    <Box mt={2} width="100%">
                        {file ? (
                            fileType === "application/pdf" ? (
                                <Worker>
                                    <Viewer fileUrl={file} />
                                </Worker>
                            ) : fileType.startsWith("image/") ? (
                                <img src={file} alt="Preview" style={{ maxWidth: "100%", maxHeight: "400px", border: "1px solid #ddd" }} />
                            ) : (
                                <div id="openseadragon-viewer" style={{ width: "100%", height: "500px" }}></div>
                            )
                        ) : (
                            <Typography variant="subtitle1" align="center">No file selected</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box mt={2}>
                <Typography variant="h5" mb={1}>Invoice Items</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Vendor Product Number</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Client Unit Cost</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Invoice Unit Price</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoiceItems.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            value={item.productID}
                                            onChange={(e) => handleItemChange(index, "productID", e.target.value)}
                                            onBlur={() => handleProductBlur(index)}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={item.description}
                                            onChange={(e) => handleItemChange(index, "description", e.target.value)}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={item.clientUnitCost}
                                            onChange={(e) => handleItemChange(index, "clientUnitCost", e.target.value)}
                                            size="small"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={item.unitPrice}
                                            onChange={(e) => handleItemChange(index, "unitPrice", e.target.value)}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>{(item.quantity * item.unitPrice).toFixed(2)}</TableCell>
                                    <TableCell>
                                        <Button color="secondary" onClick={() => handleRemoveItem(index)} size="small">Remove</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button onClick={handleAddItem} variant="contained" style={{ marginTop: "10px" }}>Add Item</Button>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default InvoiceEntry;
