import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Typography, IconButton, TextField, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import botsData from '../data/apibots.json';
import config from '../data/config.json';

const ChatWindow = styled(Paper)(({ theme }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 500,
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
}));

const ChatHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ChatContent = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(2),
    overflowY: 'auto',
}));

const ChatInput = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const FloatingChat = ({ onClose, person }) => {
    const [chatHistory, setChatHistory] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const chatContentRef = useRef(null);

    const apiKey = config.apiKey;

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [chatHistory]);

    const handleSend = async () => {
        if (inputValue.trim() === '') return;

        setChatHistory((prev) => [...prev, { sender: 'user', message: inputValue }]);

        const responseMessage = await getChatbotResponse(inputValue, person, apiKey);

        setChatHistory((prev) => [
            ...prev,
            { sender: 'bot', message: responseMessage },
        ]);

        setInputValue('');
    };

    const handleCopy = (message) => {
        navigator.clipboard.writeText(message);
    };

    const clearChat = () => {
        setChatHistory([]);
    };

    return (
        <ChatWindow elevation={5}>
            <ChatHeader>
                <Typography variant="h6">Chat with {person.name}</Typography>
                <Box>
                    <Button color="secondary" onClick={clearChat}>
                        Clear Chat
                    </Button>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </ChatHeader>
            <ChatContent ref={chatContentRef}>
                {chatHistory.map((chat, index) => (
                    <Box
                        key={index}
                        mb={2}
                        display="flex"
                        flexDirection="column"
                        alignItems={chat.sender === 'user' ? 'flex-end' : 'flex-start'}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                p: 1,
                                backgroundColor:
                                    chat.sender === 'user' ? 'primary.main' : 'grey.200',
                                color: chat.sender === 'user' ? 'common.white' : 'common.black',
                                maxWidth: '80%',
                            }}
                        >
                            <Typography variant="body1">{chat.message}</Typography>
                        </Paper>
                        {chat.sender === 'bot' && (
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(chat.message)}
                                sx={{ mt: 0.5 }}
                            >
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                ))}
            </ChatContent>
            <ChatInput>
                <TextField
                    fullWidth
                    placeholder="Type your message..."
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSend();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    sx={{ mt: 1, width: '100%' }}
                >
                    Send
                </Button>
            </ChatInput>
        </ChatWindow>
    );
};

// Update function to use personality profile
const getChatbotResponse = async (message, person, apiKey) => {
    const systemMessage = getPersonalityMessage(person);

    try {
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-3.5-turbo',
                messages: [
                    { role: 'system', content: systemMessage },
                    { role: 'user', content: message },
                ],
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${apiKey}`,
                },
            }
        );

        return response.data.choices[0]?.message?.content || 'No response received.';
    } catch (error) {
        console.error('Error fetching chatbot response:', error);
        return 'Sorry, there was an error processing your request.';
    }
};

// Use personality-specific details to craft the system message
const getPersonalityMessage = (person) => {
    return `You are ${person.name}, a ${person.title}. You are known for: ${person.vernacular}. Respond as ${person.name}, using a tone that matches their personality and expertise.`;
};

export default FloatingChat;
