import React, { useState, useEffect } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";

const Students = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [studentsData, setStudentsData] = useState([]);
    const [isEditMode, setIsEditMode] = useState({});

    useEffect(() => {
        fetchStudentsData();
    }, []);

    const fetchStudentsData = () => {
        axios.get("https://api.swancreate.com/api/pos/students")
            .then(response => setStudentsData(response.data))
            .catch(error => console.error("Error fetching students data:", error));
    };

    const handleEditClick = (id) => {
        setIsEditMode((prev) => ({ ...prev, [id]: true }));
    };

    const handleSaveClick = (id) => {
        const updatedRow = studentsData.find((row) => row.StudentGuidId === id);
        axios.put(`https://api.swancreate.com/api/pos/students/${id}`, updatedRow)
            .then(() => {
                setIsEditMode((prev) => ({ ...prev, [id]: false }));
                fetchStudentsData();
            })
            .catch(error => console.error("Error updating student data:", error));
    };

    const handleCancelClick = (id) => {
        setIsEditMode((prev) => ({ ...prev, [id]: false }));
    };

    const handleDeleteClick = (id) => {
        axios.delete(`https://api.swancreate.com/api/pos/students/${id}`)
            .then(() => fetchStudentsData())
            .catch(error => console.error("Error deleting student:", error));
    };

    const columns = [
        { field: "StudentId", headerName: "Student ID", flex: 1 },
        { field: "SchoolName", headerName: "School Name", flex: 1.5 },
        { field: "Homeroom", headerName: "Homeroom", flex: 1 },
        { field: "StudentNumber", headerName: "Student Number", flex: 1 },
        { field: "FirstName", headerName: "First Name", flex: 1 },
        { field: "LastName", headerName: "Last Name", flex: 1 },
        { field: "GradeID", headerName: "Grade", flex: 1 },
        { field: "Phone", headerName: "Phone", flex: 1 },
        { field: "Email", headerName: "Email", flex: 1 },
        { field: "Address", headerName: "Address", flex: 1.5 },
        { field: "Apt", headerName: "Apartment", flex: 0.5 },
        { field: "City", headerName: "City", flex: 1 },
        { field: "State", headerName: "State", flex: 0.5 },
        { field: "Country", headerName: "Country", flex: 0.5 },
        { field: "ZipCode", headerName: "Zip Code", flex: 0.5 },
        { field: "IsActive", headerName: "Active", flex: 0.5 },
        { field: "PaymentStatus", headerName: "Payment Status", flex: 1 },
        { field: "Gender", headerName: "Gender", flex: 0.5 },
        { field: "ProfilePhoto", headerName: "Profile Photo", flex: 1 },
        { field: "CoverPhoto", headerName: "Cover Photo", flex: 1 },
        { field: "BarcodeImage", headerName: "Barcode Image", flex: 1 },
        { field: "StudentBalance", headerName: "Balance", flex: 1 },
        { field: "Foster", headerName: "Foster", flex: 0.5 },
        { field: "Ethnicity", headerName: "Ethnicity", flex: 0.5 },
        { field: "isDC", headerName: "Directly Certified", flex: 0.5 },
        { field: "isExtendedbyHousehold", headerName: "Extended by Household", flex: 1 },
        { field: "MatchType", headerName: "Match Type", flex: 1 },
        { field: "DCType", headerName: "DC Type", flex: 0.5 },
        { field: "CaseNumber", headerName: "Case Number", flex: 1 },
        { field: "GuardianEmail", headerName: "Guardian Email", flex: 1 },
        { field: "SISFamilyCode", headerName: "SIS Family Code", flex: 1 },
        { field: "isStaff", headerName: "Staff", flex: 0.5 },
        { field: "isGeneric", headerName: "Generic", flex: 0.5 },
        { field: "isIncomeVerification", headerName: "Income Verification", flex: 1 },
        { field: "MealStatus", headerName: "Meal Status", flex: 0.5 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    {isEditMode[params.row.StudentGuidId] ? (
                        <>
                            <IconButton onClick={() => handleSaveClick(params.row.StudentGuidId)}>
                                <SaveIcon />
                            </IconButton>
                            <IconButton onClick={() => handleCancelClick(params.row.StudentGuidId)}>
                                <CancelIcon />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton onClick={() => handleEditClick(params.row.StudentGuidId)}>
                                <EditIcon />
                            </IconButton>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => handleDeleteClick(params.row.StudentGuidId)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header title="STUDENTS" subtitle="List of All Students" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                    "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                    "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                    "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
                }}
            >
                <DataGrid
                    rows={studentsData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }} // Enable the GridToolbar for built-in column toggling
                    getRowId={(row) => row.StudentGuidId}
                />
            </Box>
        </Box>
    );
};

export default Students;
