import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

const TextSenderDashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [prompt, setPrompt] = useState("");
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [consoleMessages, setConsoleMessages] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [testPhone, setTestPhone] = useState("");

    const handleSendToChatGPT = async () => {
        setLoading(true);
        setConsoleMessages(prevMessages => [...prevMessages, "Sending request to Jefferson (Swan AI Labs)..."]);

        const content = `Please create a concise, engaging, and highly deliverable text message based on this content: ${prompt}. Ensure the text is suitable for SMS delivery and not flagged as spam.`;

        try {
            const result = await axios.post("https://api.openai.com/v1/chat/completions", {
                model: "gpt-4",
                messages: [{ role: "user", content }],
                temperature: 0.7
            }, {
                headers: {
                    "Authorization": `Bearer sk-cI0ZWW9VJ8vK5aA0rBBqQZ-ynifynWgkm1e_W4VidDT3BlbkFJoVl4MqwPNmbpfpizrCpW7I7B0ezKqqAp3WNIghfIgA
`, // Replace with your actual API key
                    "Content-Type": "application/json"
                }
            });
            setResponse(result.data.choices[0].message.content);
            setConsoleMessages(prevMessages => [...prevMessages, "Response received from Jefferson."]);
        } catch (error) {
            console.error("Error sending request to Jefferson (Swan AI Labs):", error);
            setResponse("Failed to process the request.");
            setConsoleMessages(prevMessages => [...prevMessages, "Error: Failed to process the request."]);
        }
        setLoading(false);
    };

    const handleSendCampaign = () => {
        setConsoleMessages(prevMessages => [...prevMessages, "Sending campaign texts..."]);
        // Implement campaign text sending logic here
    };

    const handleSendTestText = () => {
        setConsoleMessages(prevMessages => [...prevMessages, `Sending test text to ${testPhone}...`]);
        // Implement test text sending logic here
    };

    useEffect(() => {
        axios.get('https://api.swancreate.com/api/pos/students')
            .then(response => setStudentData(response.data))
            .catch(error => console.error("Error fetching student data:", error));
    }, []);

    const columns = [
        { field: "SchoolName", headerName: "School Name", flex: 1 },
        { field: "StudentId", headerName: "Student ID", flex: 1 },
        { field: "FirstName", headerName: "First Name", flex: 1 },
        { field: "LastName", headerName: "Last Name", flex: 1 },
        { field: "Phone", headerName: "Phone Number", flex: 1 },
    ];

    return (
        <Box>
            <Header title="Text Configuration" subtitle="Design and Send Customized Text Messages" />

            {/* Instructions */}
            <Box m={4} p={3} bgcolor="#2E2E2E" color="#FFFFFF" borderRadius={2} boxShadow={3}>
                <Typography variant="h4" gutterBottom>
                    <strong>📋 How to Use the Text Sender Dashboard</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Follow these steps to create, customize, and send SMS messages:
                </Typography>
                <Typography variant="body1" component="div">
                    <ul style={{ paddingLeft: '20px' }}>
                        <li>📝 <strong>Enter your text content prompt</strong> to generate a customized SMS message.</li>
                        <li>📩 Click <strong>"Send to Jefferson"</strong> to receive a suggested message based on your prompt. You can directly edit the response or type your own message in the text box provided.</li>
                        <li>📋 <strong>Filter the table below</strong> to select the recipients for your message. Use the search and filter options to find specific students or groups.</li>
                        <li>📲 Once satisfied with the message, choose <strong>"Send Campaign"</strong> to send the text to all filtered recipients, or <strong>send a test text</strong> to a specific number.</li>
                    </ul>
                </Typography>
            </Box>

            <Box p={4} display="flex" flexDirection="row" gap={2} bgcolor="#121212">
                {/* Left Column */}
                <Box display="flex" flexDirection="column" width="40%" gap={2}>
                    <TextField
                        fullWidth
                        label="Enter Text Content Prompt"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        multiline
                        rows={4}
                        variant="filled"
                        sx={{ bgcolor: "#1e1e1e", borderRadius: 1 }}
                        InputLabelProps={{ style: { color: 'white' } }}
                        InputProps={{ style: { color: 'white' } }}
                    />
                    <Button onClick={handleSendToChatGPT} variant="contained" color="primary" disabled={loading || !prompt}>
                        {loading ? "Processing..." : "Send to Jefferson (Swan AI Labs)"}
                    </Button>
                    <Button onClick={handleSendCampaign} variant="contained" color="secondary" sx={{ mt: 2 }}>
                        Send Campaign
                    </Button>
                    <TextField
                        fullWidth
                        label="Test Phone Number"
                        value={testPhone}
                        onChange={(e) => setTestPhone(e.target.value)}
                        variant="filled"
                        sx={{ bgcolor: "#1e1e1e", borderRadius: 1, mt: 2 }}
                        InputLabelProps={{ style: { color: 'white' } }}
                        InputProps={{ style: { color: 'white' } }}
                    />
                    <Button onClick={handleSendTestText} variant="contained" color="secondary">
                        Send Test Text
                    </Button>

                    {/* Console Window */}
                    <Box mt={2} p={2} bgcolor="#1e1e1e" color="yellow" borderRadius="5px" border="1px solid #333">
                        <Typography variant="h6">Console Log</Typography>
                        {consoleMessages.map((msg, idx) => (
                            <Typography key={idx} style={{ fontFamily: 'Consolas, monospace' }}>{msg}</Typography>
                        ))}
                    </Box>
                </Box>

                {/* Right Column */}
                <Box width="60%">
                    <Typography variant="h5" mb={2} style={{ fontWeight: 'bold', color: 'white' }}>Jefferson (Swan AI Labs) Response</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={18}
                        variant="outlined"
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                        placeholder="Awaiting Jefferson response or type your text message here..."
                        sx={{
                            bgcolor: "#1E1E1E",
                            borderRadius: 1,
                            '& .MuiInputBase-root': { color: '#FFD700' },
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#333' },
                        }}
                        InputProps={{
                            style: { fontFamily: 'monospace', fontSize: '18px' },
                        }}
                    />
                </Box>
            </Box>

            {/* Student Data Table */}
            <Box mt={4} p={4} height="60vh" sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
            }}>
                <DataGrid
                    rows={studentData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.StudentId}
                    disableColumnMenu
                    pageSize={10}
                />
            </Box>
        </Box>
    );
};

export default TextSenderDashboard;
