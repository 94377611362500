import React, { useState } from 'react';
import FloatingChat from '../../components/FloatingChat';
import { Box, Typography, Grid, Avatar, Button, Tooltip, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';

const people = [
    { id: 'abrahamlincoln', name: 'Abraham Lincoln', subtitle: '16th U.S. President', lifespan: '1809�1865', image: '/assets/bots/abrahamlincoln.jpg', bio: 'Abraham Lincoln, the 16th president of the United States, led the country through the Civil War and issued the Emancipation Proclamation, which began the process of freedom for America\'s slaves. He was assassinated in 1865 but left a legacy of liberty and equality.' },
    { id: 'buddha', name: 'Buddha', subtitle: 'Spiritual Leader', lifespan: 'c. 563�c. 483 BCE', image: '/assets/bots/buddha.jpg', bio: 'The Buddha, originally named Siddhartha Gautama, was a spiritual leader who founded Buddhism after achieving enlightenment. His teachings on compassion and mindfulness continue to inspire millions of followers worldwide.' },
    { id: 'charliemunger', name: 'Charlie Munger', subtitle: 'Investor', dob: '1924', image: '/assets/bots/charliemunger.jpg', bio: 'Charlie Munger is the Vice Chairman of Berkshire Hathaway and a close partner of Warren Buffett. Known for his sharp wit and investment insight, Munger advocates for a multidisciplinary approach to thinking and decision-making.' },
    { id: 'einstein', name: 'Albert Einstein', subtitle: 'Theoretical Physicist', lifespan: '1879�1955', image: '/assets/bots/einstein.jpg', bio: 'Albert Einstein revolutionized physics with his theory of relativity and contributions to quantum mechanics. His work fundamentally changed our understanding of the universe and earned him a Nobel Prize in 1921.' },
    { id: 'elonmusk', name: 'Elon Musk', subtitle: 'Entrepreneur', dob: '1971', image: '/assets/bots/elonmusk.jpg', bio: 'Elon Musk is the CEO of Tesla and SpaceX, known for pushing technological boundaries in electric vehicles, renewable energy, and space exploration. Musk aims to reduce space travel costs and explore Mars.' },
    { id: 'madamecurie', name: 'Marie Curie', subtitle: 'Physicist and Chemist', lifespan: '1867�1934', image: '/assets/bots/madamecurie.jpg', bio: 'Marie Curie was a pioneering scientist in radioactivity, being the first person to win Nobel Prizes in two different sciences. Her research has had a lasting impact on medicine and nuclear physics.' },
    { id: 'marcusaurelius', name: 'Marcus Aurelius', subtitle: 'Roman Emperor', lifespan: '121�180', image: '/assets/bots/marcusaurelius.jpg', bio: 'Marcus Aurelius was a Roman Emperor and Stoic philosopher. His reflections, known as Meditations, are a profound source of wisdom on duty, virtue, and inner peace amidst hardship.' },
    { id: 'mothertheresa', name: 'Mother Teresa', subtitle: 'Catholic Nun', lifespan: '1910�1997', image: '/assets/bots/mothertheresa.jpg', bio: 'Mother Teresa, founder of the Missionaries of Charity, dedicated her life to helping the poorest and sickest individuals. She received the Nobel Peace Prize for her humanitarian work.' },
    { id: 'warrenbuffet', name: 'Warren Buffett', subtitle: 'Investor', dob: '1930', image: '/assets/bots/warrenbuffet.jpg', bio: 'Warren Buffett, the "Oracle of Omaha," is one of the most successful investors in history. His long-term value investing approach and philanthropy have made him a role model in business.' },
    { id: 'jefferson', name: 'Jefferson', subtitle: 'AI Nutrition Expert', dob: 'Virtual', image: '/assets/bots/jefferson.jpg', bio: 'Jefferson is an AI-driven expert on nutrition and wellness, offering advice on healthy lifestyle choices. Jefferson advocates a balanced diet and holistic approach to health.' },
    { id: 'joanofarc', name: 'Joan of Arc', subtitle: 'French Heroine', lifespan: '1412�1431', image: '/assets/bots/joanofarc.jpg', bio: 'Joan of Arc, a peasant girl, led French forces to victory against the English during the Hundred Years\' War. She was captured and burned at the stake, later becoming a canonized saint.' },
    { id: 'larryking', name: 'Larry King', subtitle: 'TV Host', lifespan: '1933�2021', image: '/assets/bots/larryking.jpg', bio: 'Larry King, known for his conversational interview style, hosted "Larry King Live" on CNN for 25 years. He interviewed thousands of prominent figures, making him a broadcasting legend.' },
    { id: 'napoleon', name: 'Napoleon Bonaparte', subtitle: 'French Emperor', lifespan: '1769�1821', image: '/assets/bots/napoleon.jpg', bio: 'Napoleon Bonaparte was a military genius and Emperor of France. He expanded his empire across Europe and implemented reforms that influenced civil law around the world.' },
    { id: 'confucius', name: 'Confucius', subtitle: 'Philosopher', lifespan: '551�479 BCE', image: '/assets/bots/confucius.jpg', bio: 'Confucius was a Chinese philosopher whose teachings on ethics, family loyalty, and respect for tradition have shaped East Asian cultures for centuries.' },
    { id: 'alexanderthegreat', name: 'Alexander the Great', subtitle: 'King of Macedonia', lifespan: '356�323 BCE', image: '/assets/bots/alexanderthegreat.jpg', bio: 'Alexander the Great, King of Macedonia, built one of the largest empires in history. His conquests spread Greek culture throughout the known world, shaping the Hellenistic Era.' }
];

const CenteredGrid = styled(Grid)({
    maxWidth: '1200px',
    margin: '0 auto',
    paddingTop: '50px',
});

const AvatarCard = styled(Box)(({ theme }) => ({
    padding: '16px',
    backgroundColor: '#2D3748',
    borderRadius: '8px',
    textAlign: 'center',
    color: '#E2E8F0',
    position: 'relative',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
}));

const JeffersonAvatarPage = () => {
    const [activePerson, setActivePerson] = useState(null);
    const [bioModalPerson, setBioModalPerson] = useState(null);

    const openChat = (person) => {
        setActivePerson(person);
    };

    const closeChat = () => {
        setActivePerson(null);
    };

    const openBioModal = (person) => {
        setBioModalPerson(person);
    };

    const closeBioModal = () => {
        setBioModalPerson(null);
    };

    return (
        <Box sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#1A202C' }}>
            <CenteredGrid container spacing={3} justifyContent="center">
                {people.map((person) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={person.id}>
                        <AvatarCard>
                            <Avatar
                                alt={person.name}
                                src={person.image}
                                sx={{ width: 80, height: 80, margin: '0 auto', cursor: 'pointer' }}
                                onClick={() => openChat(person)}
                            />
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                {person.name}
                            </Typography>
                            <Typography variant="body2">
                                {person.subtitle}
                            </Typography>
                            <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                                {person.lifespan || person.dob}
                            </Typography>

                            <IconButton
                                aria-label="info"
                                onClick={() => openBioModal(person)}
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    color: '#A0AEC0',
                                }}
                            >
                                <InfoIcon />
                            </IconButton>

                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ mt: 2, color: '#E2E8F0', borderColor: '#A0AEC0' }}
                                onClick={() => openChat(person)}
                            >
                                Chat
                            </Button>
                        </AvatarCard>
                    </Grid>
                ))}
            </CenteredGrid>

            {/* Centered Floating Chat Window */}
            {activePerson && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <FloatingChat onClose={closeChat} person={activePerson} />
                </Box>
            )}

            {/* Full Bio Modal */}
            {bioModalPerson && (
                <Modal
                    open={Boolean(bioModalPerson)}
                    onClose={closeBioModal}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Box sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.95)',
                        padding: 4,
                        borderRadius: '8px',
                        width: '80%',
                        maxWidth: '600px',
                        color: '#000',
                        overflowY: 'auto',
                        maxHeight: '80vh',
                    }}>
                        <Typography variant="h6" sx={{ mb: 2, color: '#333' }}>
                            {bioModalPerson.name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#666', mb: 2 }}>
                            {bioModalPerson.subtitle} ({bioModalPerson.lifespan || bioModalPerson.dob})
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#555', whiteSpace: 'pre-wrap' }}>
                            {bioModalPerson.bio}
                        </Typography>
                        <Button onClick={closeBioModal} sx={{ mt: 3, color: '#2B6CB0' }}>
                            Close
                        </Button>
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export default JeffersonAvatarPage;
