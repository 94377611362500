import React, { useState } from "react";
import { Box, Typography, IconButton, Collapse, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TableChartIcon from "@mui/icons-material/TableChart";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SchoolIcon from "@mui/icons-material/School";
import ChatIcon from "@mui/icons-material/Chat";
import CommunicationIcon from "@mui/icons-material/Forum";
import PeopleIcon from "@mui/icons-material/People";
import VisibilityIcon from '@mui/icons-material/Visibility'; // Icon for Avatar Link

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isCollapsed, setIsCollapsed] = useState(isMobile);
    const [selected, setSelected] = useState("Dashboard");
    const [studentDataOpen, setStudentDataOpen] = useState(false);
    const [incomeExpenseOpen, setIncomeExpenseOpen] = useState(false);
    const [employeeDataOpen, setEmployeeDataOpen] = useState(false);
    const [communicationOpen, setCommunicationOpen] = useState(false);

    React.useEffect(() => {
        setIsCollapsed(isMobile);
    }, [isMobile]);

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": { background: `${colors.primary[400]} !important` },
                "& .pro-icon-wrapper": { backgroundColor: "transparent !important" },
                "& .pro-inner-item": { padding: "5px 35px 5px 20px !important" },
                "& .pro-inner-item:hover": { color: "#868dfb !important" },
                "& .pro-menu-item.active": { color: "#6870fa !important" },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={<MenuOutlinedIcon />}
                        style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
                    >
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>FIG K12 POS</Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="20px" display="flex" justifyContent="center" alignItems="center">
                            <img src="https://figk12pos.com/static/media/fig_CROP.cb0d7cf4314c63ba05ee.png"
                                alt="FIG K12 POS Logo"
                                width="80px" height="80px" style={{ cursor: "pointer", borderRadius: "8px" }}
                            />
                        </Box>
                    )}

                    {/* Dashboard items */}
                    <Item title="Finance Dashboard" to="/" icon={<DashboardIcon />} selected={selected} setSelected={setSelected} />
                    <Item title="Student Dashboard" to="/dashboardstudent" icon={<DashboardIcon />} selected={selected} setSelected={setSelected} />

                    {/* Student Data Section */}
                    <Box mb="10px">
                        <MenuItem
                            icon={<SchoolIcon />}
                            onClick={() => setStudentDataOpen(!studentDataOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>Student Data</Typography>
                                <IconButton>
                                    {studentDataOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </MenuItem>
                        <Collapse in={studentDataOpen}>
                            <Box pl="20px">
                                <Item title="Extended by Household" to="/extended" icon={<GroupAddIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Genesis Data" to="/genesis" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Students Table" to="/students" icon={<TableChartIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>

                    {/* Income & Expense Section */}
                    <Box mb="10px">
                        <MenuItem
                            icon={<AttachMoneyIcon />}
                            onClick={() => setIncomeExpenseOpen(!incomeExpenseOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>Income & Expense</Typography>
                                <IconButton>
                                    {incomeExpenseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </MenuItem>
                        <Collapse in={incomeExpenseOpen}>
                            <Box pl="20px">
                                <Item title="Invoices Balances" to="/invoices" icon={<ReceiptOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Invoice Entry" to="/invoice_entry" icon={<ReceiptOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Vendors" to="/vendors" icon={<BusinessOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Contacts Information" to="/contacts" icon={<ContactsOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>

                    {/* Employee Data Section */}
                    <Box mb="10px">
                        <MenuItem
                            icon={<WorkOutlineIcon />}
                            onClick={() => setEmployeeDataOpen(!employeeDataOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>Employee Data</Typography>
                                <IconButton>
                                    {employeeDataOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </MenuItem>
                        <Collapse in={employeeDataOpen}>
                            <Box pl="20px">
                                <Item title="Manage Team" to="/team" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Employee Management" to="/employee-management" icon={<WorkOutlineIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Chat" to="/chat" icon={<ChatIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="User Profile" to="/profile" icon={<AccountCircleIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>

                    {/* Communication Section */}
                    <Box mb="10px">
                        <MenuItem
                            icon={<CommunicationIcon />}
                            onClick={() => setCommunicationOpen(!communicationOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>Communication</Typography>
                                <IconButton>
                                    {communicationOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </MenuItem>
                        <Collapse in={communicationOpen}>
                            <Box pl="20px">
                                <Item title="Text Config" to="/textconfig" icon={<TextsmsOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Email Config" to="/emailconfig" icon={<EmailOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="AI Instructors" to="/jeffersonsandbox" icon={<PeopleIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="AI Instructor DL" to="/jeffersonsandboxdl" icon={<PeopleIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Avatar Link" to="/avatarlink" icon={<VisibilityIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
