import React, { useState, useEffect } from "react";
import { Box, IconButton, Switch, Tooltip, Select, MenuItem, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";

const Invoices = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [invoiceData, setInvoiceData] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [isEditMode, setIsEditMode] = useState({});

    useEffect(() => {
        fetchInvoices();
        fetchVendors();
        fetchStatuses();
    }, []);

    const fetchInvoices = () => {
        axios.get("https://api.swancreate.com/finance/invoices")
            .then(response => setInvoiceData(response.data))
            .catch(error => console.error("Error fetching invoice data:", error));
    };

    const fetchVendors = () => {
        axios.get("https://api.swancreate.com/finance/vendors")
            .then(response => setVendors(response.data))
            .catch(error => console.error("Error fetching vendor data:", error));
    };

    const fetchStatuses = () => {
        axios.get("https://api.swancreate.com/finance/invoice-status")
            .then(response => setStatuses(response.data))
            .catch(error => console.error("Error fetching statuses:", error));
    };

    const handleEditClick = (id) => {
        setIsEditMode((prev) => ({ ...prev, [id]: true }));
    };

    const handleSaveClick = (id) => {
        const updatedRow = invoiceData.find((row) => row.InvoiceID === id);
        axios.put(`https://api.swancreate.com/finance/invoices/${id}`, updatedRow)
            .then(() => {
                setIsEditMode((prev) => ({ ...prev, [id]: false }));
                fetchInvoices();
            })
            .catch(error => console.error("Error updating invoice data:", error));
    };

    const handleCancelClick = (id) => {
        setIsEditMode((prev) => ({ ...prev, [id]: false }));
    };

    const handleDeleteClick = (id) => {
        axios.delete(`https://api.swancreate.com/pos/invoices/${id}`)
            .then(() => fetchInvoices())
            .catch(error => console.error("Error deleting invoice:", error));
    };

    const handleRowEdit = (id, field, value) => {
        setInvoiceData((prevData) =>
            prevData.map((row) =>
                row.InvoiceID === id ? { ...row, [field]: value } : row
            )
        );
    };

    const columns = [
        { field: "InvoiceNumber", headerName: "Invoice Number", flex: 1 },
        {
            field: "VendorID",
            headerName: "Vendor Name",
            flex: 1,
            renderCell: (params) => {
                const vendor = vendors.find(v => v.VendorID === params.value);
                return vendor ? vendor.VendorName : "Unknown";
            }
        },
        {
            field: "InvoiceDate",
            headerName: "Date",
            flex: 1,
            renderCell: (params) => new Date(params.value).toLocaleDateString("en-US"),
        },
        {
            field: "TotalAmount",
            headerName: "Total Amount",
            flex: 1,
            renderCell: (params) => params.value.toLocaleString("en-US", { style: "currency", currency: "USD" })
        },
        { field: "Description", headerName: "Description", flex: 2, editable: true },
        {
            field: "StatusID",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => isEditMode[params.row.InvoiceID] ? (
                <Select
                    value={params.value}
                    onChange={(e) => handleRowEdit(params.row.InvoiceID, "StatusID", e.target.value)}
                    sx={{ minWidth: 120 }}
                >
                    {statuses.map((status) => (
                        <MenuItem key={status.StatusID} value={status.StatusID}>
                            {status.StatusName}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <Typography>
                    {statuses.find(status => status.StatusID === params.value)?.StatusName || "Unknown"}
                </Typography>
            )
        },
        {
            field: "isActive",
            headerName: "Active",
            flex: 0.5,
            renderCell: (params) => (
                <Switch
                    checked={params.row.isActive}
                    onChange={() => handleRowEdit(params.row.InvoiceID, "isActive", !params.row.isActive)}
                    color="primary"
                />
            )
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    {isEditMode[params.row.InvoiceID] ? (
                        <>
                            <IconButton onClick={() => handleSaveClick(params.row.InvoiceID)}>
                                <SaveIcon />
                            </IconButton>
                            <IconButton onClick={() => handleCancelClick(params.row.InvoiceID)}>
                                <CancelIcon />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton onClick={() => handleEditClick(params.row.InvoiceID)}>
                                <EditIcon />
                            </IconButton>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => handleDeleteClick(params.row.InvoiceID)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header title="INVOICES" subtitle="List of Invoice Balances" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={invoiceData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.InvoiceID}
                />
            </Box>
        </Box>
    );
};

export default Invoices;
