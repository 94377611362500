import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { mockTransactions } from "../../data/mockData";
import { ResponsiveBar } from '@nivo/bar';

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [studentData, setStudentData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    console.log('colors:', colors);

    useEffect(() => {
        // Fetch general student dashboard data
        axios.get('https://api.swancreate.com/api/pos//view/vw_studentdashboard')
            .then((response) => setStudentData(response.data))
            .catch((error) => console.error("Error fetching student data:", error));

        // Fetch site-specific student distribution data
        axios.get('https://api.swancreate.com/api/pos/view/vw_PaymentStatusSummaryBySite')
        .then((response) => {
          console.log('Site Data:', response.data);
          setSiteData(response.data);
        })
        .catch((error) => console.error("Error fetching site data:", error));
    }, []);

    const totalStudents = studentData.find(data => data.Metric === "Total Active Students");
    const freeData = studentData.find(data => data.Metric === "Payment Status F");
    const reducedData = studentData.find(data => data.Metric === "Payment Status R");
    const paidData = studentData.find(data => data.Metric === "Payment Status P");
    const directCertTotal = studentData.find(data => data.Metric === "Directly Certified Students");
    const dcFree = studentData.find(data => data.Metric === "Direct Certification Type FREE");
    const dcReduced = studentData.find(data => data.Metric === "Direct Certification Type REDUCED");

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                    Download Reports
                </Button>
            </Box>

            {/* GRID & CHARTS */}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
                {/* ROW 1 - StatBoxes */}
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={totalStudents?.Value || 0}
                        subtitle="Total Students"
                        icon={<EmailIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
                    />
                </Box>
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={freeData?.Value || 0}
                        subtitle="Free Students"
                        progress={(freeData?.Percentage || 0) / 100}
                        increase={freeData?.Percentage ? `${freeData.Percentage.toFixed(2)}%` : null}
                        icon={<EmailIcon sx={{ color: colors.greenAccent[400], fontSize: "26px" }} />}
                    />
                </Box>
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={reducedData?.Value || 0}
                        subtitle="Reduced Students"
                        progress={(reducedData?.Percentage || 0) / 100}
                        increase={reducedData?.Percentage ? `${reducedData.Percentage.toFixed(2)}%` : null}
                        icon={<EmailIcon sx={{ color: colors.blueAccent[400], fontSize: "26px" }} />}
                    />
                </Box>
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={paidData?.Value || 0}
                        subtitle="Paid Students"
                        progress={(paidData?.Percentage || 0) / 100}
                        increase={paidData?.Percentage ? `${paidData.Percentage.toFixed(2)}%` : null}
                        icon={<EmailIcon sx={{ color: colors.redAccent[400], fontSize: "26px" }} />}
                    />
                </Box>

                {/* ROW 2 - Direct Certification StatBoxes */}
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={directCertTotal?.Value || 0}
                        subtitle="Directly Certified"
                        progress={(directCertTotal?.Percentage || 0) / 100}
                        increase={directCertTotal?.Percentage ? `${directCertTotal.Percentage.toFixed(2)}%` : null}
                        icon={<EmailIcon sx={{ color: colors.greenAccent[500], fontSize: "26px" }} />}
                    />
                </Box>
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={dcFree?.Value || 0}
                        subtitle="DC Free Students"
                        icon={<EmailIcon sx={{ color: colors.greenAccent[300], fontSize: "26px" }} />}
                    />
                </Box>
                <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                    <StatBox
                        title={dcReduced?.Value || 0}
                        subtitle="DC Reduced Students"
                        icon={<EmailIcon sx={{ color: colors.blueAccent[300], fontSize: "26px" }} />}
                    />
                </Box>

                {/* ROW 2: Bar Chart with Free, Reduced, Paid Distribution by Site */}
                <Box gridColumn="span 8" gridRow="span 2" backgroundColor={colors.primary[400]}>
                    <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                            Free/Reduced/Paid Student Distribution by Site
                        </Typography>
                        <IconButton>
                            <DownloadOutlinedIcon sx={{ fontSize: "26px", color: colors.greenAccent[500] }} />
                        </IconButton>
                    </Box>
                    <Box height="300px" mt="-20px" pl="10px">
                        <ResponsiveBar
                            data={siteData}
                            keys={['FreeCount', 'ReducedCount', 'PaidCount']}
                            indexBy="SiteName"
                            margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            colors={({ id }) => {
                                switch (id) {
                                    case 'FreeCount':
                                        return colors.greenAccent[500];
                                    case 'ReducedCount':
                                        return colors.blueAccent[500];
                                    case 'PaidCount':
                                        return colors.redAccent[500];
                                    default:
                                        return colors.grey[500];
                                }
                            }}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Site Name',
                                legendPosition: 'middle',
                                legendOffset: 40,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Student Count',
                                legendPosition: 'middle',
                                legendOffset: -40,
                            }}
                            tooltip={({ id, value }) => (
                                <div
                                    style={{
                                        padding: '6px',
                                        background: 'white',
                                        border: '1px solid #ccc',
                                    }}
                                >
                                    <strong>{`${id} - ${value}`}</strong>
                                </div>
                            )}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1,
                                            },
                                        },
                                    ],
                                },
                            ]}
                            role="application"
                            ariaLabel="Free/Reduced/Paid Student Distribution by Site"
                            barAriaLabel={(e) => `${e.id}: ${e.value} students at ${e.indexValue}`}
                        />
                    </Box>
                </Box>

                {/* ROW 3 - Existing Components */}
                <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} p="30px">
                    <Typography variant="h5" fontWeight="600">
                        Campaign
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
                        <ProgressCircle size="125" />
                        <Typography variant="h5" color={colors.greenAccent[500]} sx={{ mt: "15px" }}>
                            $48,352 revenue generated
                        </Typography>
                        <Typography>Includes extra misc expenditures and costs</Typography>
                    </Box>
                </Box>
                <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]}>
                    <Typography variant="h5" fontWeight="600" sx={{ padding: "30px 30px 0 30px" }}>
                        Sales Quantity
                    </Typography>
                    <Box height="250px" mt="-20px">
                        <BarChart isDashboard={true} />
                    </Box>
                </Box>
                <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} padding="30px">
                    <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "15px" }}>
                        Geography Based Traffic
                    </Typography>
                    <Box height="200px">
                        <GeographyChart isDashboard={true} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
