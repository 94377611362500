
import React, { useEffect } from "react";

const BuddhaEmbed = () => {
    useEffect(() => {
        const container = document.getElementById("Buddha-avatar");
        if (container) {
            const script = document.createElement("script");
            script.src = "http://pos-stu.swancreate.com/js/Buddha_botlink.js"; // Correct URL for each bot
            script.async = true;
            container.appendChild(script);
        } else {
            console.error("Buddha Avatar container not found.");
        }
    }, []);

    return null;
};

export default BuddhaEmbed;
