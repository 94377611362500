import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";

const Genesis = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [studentStatusData, setStudentStatusData] = useState([]);

    useEffect(() => {
        fetchGenesisStudentStatus();
    }, []);

    // Fetch the student status data from the API
    const fetchGenesisStudentStatus = () => {
        axios
            .get("https://api.swancreate.com/api/pos/view/vw_GenesisStudentStatus")
            .then((response) => {
                // Add rowCounter as a unique ID for each row
                const dataWithRowCounter = response.data.map((row, index) => ({
                    rowCounter: index + 1, // Row Counter starts from 1
                    id: row.StudentID ? row.StudentID : `fallback-${index + 1}`, // Use StudentID if available, else fallback to rowCounter
                    ...row,
                }));
                setStudentStatusData(dataWithRowCounter);
            })
            .catch((error) => console.error("Error fetching Genesis Student Status data:", error));
    };

    const columns = [
        { field: "rowCounter", headerName: "Row", width: 90 }, // Row counter as the first column
        { field: "SchoolYear", headerName: "School Year", flex: 1 },
        { field: "StudentID", headerName: "Student ID", flex: 1 },
        { field: "JoiningDate", headerName: "Joining Date", flex: 1 },
        { field: "EndDate", headerName: "End Date", flex: 1 },
        { field: "PaymentStatus", headerName: "Payment Status", flex: 1 },
    ];

    return (
        <Box m="20px">
            <Header title="GENESIS STUDENT STATUS" subtitle="List of Genesis Student Payment Status" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                    "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                    "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                    "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
                }}
            >
                <DataGrid
                    rows={studentStatusData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.id} // Use `id` for each row
                />
            </Box>
        </Box>
    );
};

export default Genesis;
