import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import { DataGrid as DataGrid2, GridToolbar as GridToolbar2 } from "@mui/x-data-grid";

const HouseholdExtendedStudents = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [studentsData, setStudentsData] = useState([]);

    useEffect(() => {
        fetchExtendedStudents();
    }, []);

    const fetchExtendedStudents = () => {
        axios
            .get("https://api.swancreate.com/api/pos/view/vw_ExtendedHouseholdStudents")
            .then((response) => setStudentsData(response.data))
            .catch((error) => console.error("Error fetching extended household students data:", error));
    };

    const columns = [
        { field: "ExtendedStudentId", headerName: "Extended Student ID", flex: 1 },
        { field: "ExtendedStudentName", headerName: "Student Name", flex: 1.5 },
        { field: "ExtendedAddress", headerName: "Address", flex: 1.5 },
        { field: "ExtendedApt", headerName: "Apartment", flex: 1 },
        { field: "ExtendedCity", headerName: "City", flex: 1 },
        { field: "ExtendedState", headerName: "State", flex: 0.5 },
        { field: "ExtendedZipCode", headerName: "Zip Code", flex: 1 },
        { field: "OriginalDCStudentId", headerName: "Original DC Student ID", flex: 1 },
        { field: "OriginalDCStudentName", headerName: "Original DC Student Name", flex: 1.5 },
        {
            field: "isDC",
            headerName: "Directly Certified",
            flex: 0.5,
            renderCell: (params) => (params.value ? "Yes" : "No"),
        },
    ];

    return (
        <Box m="20px">
            <Header
                title="HOUSEHOLD EXTENDED STUDENTS"
                subtitle="List of Students Extended by Household Benefits"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={studentsData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.ExtendedStudentGuidId}
                />
            </Box>
        </Box>
    );
};

export default HouseholdExtendedStudents;
