
import React, { useEffect } from "react";

const AbrahamlincolnEmbed = () => {
    useEffect(() => {
        const container = document.getElementById("Abrahamlincoln-avatar");
        if (container) {
            const script = document.createElement("script");
            script.src = "http://pos-stu.swancreate.com/js/Abrahamlincoln_botlink.js"; // Correct URL for each bot
            script.async = true;
            container.appendChild(script);
        } else {
            console.error("Abrahamlincoln Avatar container not found.");
        }
    }, []);

    return null;
};

export default AbrahamlincolnEmbed;
