import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import axios from "axios";

const Chat = () => {
    const [fileContent, setFileContent] = useState("");
    const [prompt, setPrompt] = useState("");
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState("");

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        setFileName(file.name);

        reader.onload = () => {
            setFileContent(reader.result);
        };

        reader.readAsText(file);
    };

    const CHUNK_SIZE = 9000; // Adjust as needed to stay within the model's limit.

    const handleSendToChatGPT = async () => {
        if (!prompt && !fileContent) {
            setResponse("Please enter a prompt or upload a file.");
            return;
        }

        setLoading(true);
        setResponse(""); // Clear previous response

        const content = `${prompt}\n\n${fileContent || ''}`;

        // Handle chunking only if there is file content
        const chunks = [];
        if (fileContent) {
            for (let i = 0; i < fileContent.length; i += CHUNK_SIZE) {
                chunks.push(fileContent.slice(i, i + CHUNK_SIZE));
            }
        } else {
            chunks.push(content); // Just one chunk if only prompt is provided
        }

        let fullResponse = "";
        for (const chunk of chunks) {
            try {
                const result = await axios.post("https://api.openai.com/v1/chat/completions", {
                    model: "gpt-4",
                    messages: [{ role: "user", content: chunk }],
                    temperature: 0.7,
                }, {
                    headers: {
                        "Authorization": `Bearer sk-oB9xc7ELsGEeHmxqQnggxwj2qoP3iqxtwkc9qgccqaT3BlbkFJjGMlieemM3HkbEcvcGvoHZ8eT3vnhyDp-XL4zE3J0A`,
                        "Content-Type": "application/json"
                    }
                });
                fullResponse += result.data.choices[0].message.content;
            } catch (error) {
                console.error("Error sending request to ChatGPT:", error);
                fullResponse += " [Error occurred in processing this chunk.]";
            }
        }

        setResponse(fullResponse);
        setLoading(false);
    };


    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ".txt,.md" });

    return (
        <Box p={4} display="flex" flexDirection="row" alignItems="flex-start" gap={2}>
            {/* Left Column */}
            <Box display="flex" flexDirection="column" width="40%" gap={2}>
                <Box {...getRootProps()} style={{ border: "2px dashed #ccc", padding: "20px", cursor: "pointer", textAlign: "center" }}>
                    <input {...getInputProps()} />
                    <Typography>
                        Drag and drop a file, or click to select (.txt, .md)
                    </Typography>
                </Box>
                {fileName && (
                    <Typography variant="subtitle1">Selected File: {fileName}</Typography>
                )}
                <TextField
                    fullWidth
                    label="Enter Prompt"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    multiline
                    rows={4}
                />
                <Button onClick={handleSendToChatGPT} variant="contained" color="primary" disabled={loading || (!prompt && !fileContent)}>
                    {loading ? "Processing..." : "Send to ChatGPT"}
                </Button>
            </Box>

            {/* Right Column */}
            <Box width="60%">
                <Typography variant="h5" mb={2} style={{ fontWeight: 'bold' }}>ChatGPT Response</Typography>
                <Box style={{ backgroundColor: "#1E1E1E", color: "#FFD700", fontFamily: "monospace", padding: "20px", minHeight: "400px", fontSize: "18px", borderRadius: "5px", overflowY: "auto" }}>
                    <Typography style={{ whiteSpace: "pre-wrap" }}>{response || "Awaiting response..."}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Chat;
