import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";

const Vendors = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [vendorData, setVendorData] = useState([]);

    useEffect(() => {
        // Fetch data from the Finance_Vendors table API
        axios.get("http://localhost:8676/finance/vendors")
            .then(response => {
                console.log("Vendor Data:", response.data); // Log data for debugging
                setVendorData(response.data);
            })
            .catch(error => {
                console.error("Error fetching vendor data:", error);
            });
    }, []);

    const columns = [
        { field: "VendorName", headerName: "Vendor Name", flex: 1 },
        { field: "Address", headerName: "Address", flex: 1 },
        { field: "City", headerName: "City", flex: 1 },
        { field: "State", headerName: "State", flex: 1 },
        { field: "ZipCode", headerName: "Zip Code", flex: 1 },
        { field: "Country", headerName: "Country", flex: 1 },
        { field: "isActive", headerName: "Active Status", flex: 1, type: "boolean" },
        { field: "CreatedOn", headerName: "Created On", flex: 1, type: "dateTime" },
        { field: "PaymentContactName", headerName: "Payment Contact Name", flex: 1 },
        { field: "PaymentContactEmail", headerName: "Payment Contact Email", flex: 1 },
        { field: "PaymentContactPhone", headerName: "Payment Contact Phone", flex: 1 },
        { field: "PaymentAddress", headerName: "Payment Address", flex: 1 },
        { field: "PaymentCity", headerName: "Payment City", flex: 1 },
        { field: "PaymentState", headerName: "Payment State", flex: 1 },
        { field: "PaymentZip", headerName: "Payment Zip", flex: 1 },
        { field: "PaymentCountry", headerName: "Payment Country", flex: 1 },
        { field: "SpecialInstructions", headerName: "Special Instructions", flex: 1 },
        { field: "CompanyName", headerName: "Company Name", flex: 1 },
        { field: "WebSite", headerName: "Website", flex: 1 },
        { field: "AdditionalNotes", headerName: "Additional Notes", flex: 1 },
        { field: "CompanyDocumentURL", headerName: "Document URL", flex: 1 },
        { field: "UpdatedOn", headerName: "Updated On", flex: 1, type: "dateTime" },
    ];

    return (
        <Box m="20px">
            <Header
                title="VENDORS"
                subtitle="List of Vendors for Financial Management"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={vendorData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.VendorID} // Ensure unique row ID with VendorID
                />
            </Box>
        </Box>
    );
};

export default Vendors;
