import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header"; 

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [team, setTeam] = useState([]);

    useEffect(() => {
        // Replace with your actual API endpoint for user data
        axios.get("http://localhost:8676/api/userMst")
            .then(response => {
                setTeam(response.data);
            })
            .catch(error => {
                console.error("Error fetching user data:", error);
            });
    }, []);

    const columns = [
        { field: "UserId", headerName: "ID", flex: 1 },
        { field: "FirstName", headerName: "First Name", flex: 1 },
        { field: "LastName", headerName: "Last Name", flex: 1 },
        { field: "Email", headerName: "Email", flex: 1 },
        { field: "Mobile", headerName: "Phone Number", flex: 1 },
        {
            field: "RoleId",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({ row: { RoleId } }) => {
                let access = '';
                let icon = null;

                if (RoleId === 1) {
                    access = 'Admin';
                    icon = <AdminPanelSettingsOutlinedIcon />;
                } else if (RoleId === 2) {
                    access = 'Manager';
                    icon = <SecurityOutlinedIcon />;
                } else {
                    access = 'User';
                    icon = <LockOpenOutlinedIcon />;
                }

                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            RoleId === 1
                                ? colors.greenAccent[600]
                                : RoleId === 2
                                    ? colors.greenAccent[700]
                                    : colors.greenAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {icon}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {access}
                        </Typography>
                    </Box>
                );
            },
        },
        { field: "JobTitle", headerName: "Job Title", flex: 1 },
        {
            field: "IsActive",
            headerName: "Active",
            flex: 0.5,
            renderCell: ({ value }) => (
                <Typography color={value ? colors.greenAccent[400] : colors.redAccent[400]}>
                    {value ? "Yes" : "No"}
                </Typography>
            ),
        },
    ];

    return (
        <Box m="20px">
            <Header title="TEAM" subtitle="Managing the Team Members" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    checkboxSelection
                    rows={team}
                    columns={columns}
                    getRowId={(row) => row.UserId} // Use UserId as the unique identifier
                />
            </Box>
        </Box>
    );
};

export default Team;
