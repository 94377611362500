
import React, { useEffect } from "react";

const ElonmuskEmbed = () => {
    useEffect(() => {
        const container = document.getElementById("Elonmusk-avatar");
        if (container) {
            const script = document.createElement("script");
            script.src = "http://pos-stu.swancreate.com/js/Elonmusk_botlink.js"; // Correct URL for each bot
            script.async = true;
            container.appendChild(script);
        } else {
            console.error("Elonmusk Avatar container not found.");
        }
    }, []);

    return null;
};

export default ElonmuskEmbed;
