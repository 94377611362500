import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Switch, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";

const Vendors = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [vendorData, setVendorData] = useState([]);
    const [isEditMode, setIsEditMode] = useState({});
    const [showOnlyActive, setShowOnlyActive] = useState(true);

    useEffect(() => {
        fetchVendors();
    }, [showOnlyActive]);

    const fetchVendors = () => {
        axios.get("http://localhost:8676/finance/vendors")
            .then(response => {
                const data = response.data;
                const filteredData = showOnlyActive ? data.filter(vendor => vendor.isActive) : data;
                setVendorData(filteredData);
            })
            .catch(error => {
                console.error("Error fetching vendor data:", error);
            });
    };

    const handleEditClick = (id) => {
        setIsEditMode((prev) => ({ ...prev, [id]: true }));
    };

    const handleSaveClick = (id) => {
        const updatedRow = vendorData.find((row) => row.VendorID === id);
        axios.put(`http://localhost:8676/finance/vendors/${id}`, updatedRow)
            .then(() => {
                setIsEditMode((prev) => ({ ...prev, [id]: false }));
                fetchVendors();
            })
            .catch(error => {
                console.error("Error updating vendor data:", error);
            });
    };

    const handleCancelClick = (id) => {
        setIsEditMode((prev) => ({ ...prev, [id]: false }));
    };

    const handleDeleteClick = (id) => {
        axios.delete(`http://localhost:8676/finance/vendors/${id}`)
            .then(() => {
                fetchVendors();
            })
            .catch(error => {
                console.error("Error deleting vendor:", error);
            });
    };

    const handleToggleActive = () => {
        setShowOnlyActive(!showOnlyActive);
    };

const handleActiveToggle = (id, currentIsActive) => {
    const updatedIsActive = !currentIsActive; // Toggle the current value

    axios.put(`http://localhost:8676/finance/vendors/${id}/active`, { isActive: updatedIsActive })
        .then(() => {
            // Refresh data or update the state as needed
            fetchVendors();
        })
        .catch((error) => {
            console.error("Error updating vendor status:", error);
        });
};



    const columns = [
        { field: "VendorName", headerName: "Vendor Namess", flex: 1.5, editable: true },
        { field: "Address", headerName: "Address", flex: 1, editable: true },
        { field: "City", headerName: "City", flex: 0.7, editable: true },
        { field: "State", headerName: "State", flex: 0.5, editable: true },
        { field: "ZipCode", headerName: "Zip Code", flex: 0.7, editable: true },
        { field: "Country", headerName: "Country", flex: 0.7, editable: true },
        { 
            field: "isActive", 
            headerName: "Active", 
            flex: 0.5,
            renderCell: (params) => (
                <Switch 
                    checked={params.row.isActive} 
                    onChange={() => handleActiveToggle(params.row.VendorID, params.row.isActive)} 
                    color="primary"
                />
            )
        },
        { field: "PaymentContactName", headerName: "Payment Contact", flex: 1, editable: true },
        { field: "PaymentContactEmail", headerName: "Contact Email", flex: 1, editable: true },
        { field: "WebSite", headerName: "Website", flex: 1, editable: true },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    {isEditMode[params.row.VendorID] ? (
                        <>
                            <IconButton onClick={() => handleSaveClick(params.row.VendorID)}>
                                <SaveIcon />
                            </IconButton>
                            <IconButton onClick={() => handleCancelClick(params.row.VendorID)}>
                                <CancelIcon />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton onClick={() => handleEditClick(params.row.VendorID)}>
                                <EditIcon />
                            </IconButton>
                            <Tooltip title="Delete one">
                                <IconButton onClick={() => handleDeleteClick(params.row.VendorID)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
        },
    ];

    return (
        <Box m="20px">
            <Header
                title="VENDORS"
                subtitle="List of Vendors for Financial Management"
            />
            <Button 
                onClick={handleToggleActive}
                variant="contained"
                sx={{ mb: 2 }}
            >
                {showOnlyActive ? 'Show All Vendors' : 'Show Active Only'}
            </Button>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={vendorData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.VendorID}
                />
            </Box>
        </Box>
    );
};

export default Vendors;
