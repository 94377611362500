import React, { useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import config from '../data/config.json';

const ChatConsole = ({ person, promptStyle, responseStyle }) => {
    const [chatLog, setChatLog] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen is small
    const apiKey = config.apiKey; // Ensure API key is loaded from config.json

    const handleSend = async () => {
        if (inputValue.trim() === '') return;

        // Add user message to chat log
        setChatLog((prevLog) => [...prevLog, { sender: 'You', message: inputValue }]);

        // Get OpenAI response
        const responseMessage = await getChatbotResponse(inputValue, person, apiKey);

        // Add response message to chat log
        setChatLog((prevLog) => [...prevLog, { sender: person.name, message: responseMessage }]);
        setInputValue('');
    };

    const getChatbotResponse = async (message, person, apiKey) => {
        // Construct a personality-based system message
        const systemMessage = `You are ${person.name}. ${person.vernacular} Respond as ${person.name}, known as a ${person.title}, and tailor your response to align with their known attributes and style.`;

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-3.5-turbo',
                    messages: [
                        { role: 'system', content: systemMessage },
                        { role: 'user', content: message },
                    ],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            );
            return response.data.choices[0]?.message?.content || 'No response received.';
        } catch (error) {
            console.error('Error fetching chatbot response:', error);
            return 'Error: Could not fetch response.';
        }
    };

    return (
        <Paper
            sx={{
                padding: '20px',
                borderRadius: '8px',
                color: '#FFFFFF',
                width: isMobile ? '100%' : '500px', // Full width on mobile
                maxWidth: '90vw', // Max width for desktop responsiveness
                mx: 'auto' // Center align horizontally
            }}
        >
            <Box
                sx={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    ...responseStyle
                }}
            >
                {chatLog.map((chat, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{chat.sender}:</Typography>
                        <Typography variant="body2">{chat.message}</Typography>
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile
                    mt: 2,
                    ...promptStyle
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your message..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                    sx={{ mb: isMobile ? 1 : 0 }} // Add margin bottom on mobile for spacing
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    sx={{
                        ml: isMobile ? 0 : 1,
                        mt: isMobile ? 1 : 0 // Add margin on top for mobile
                    }}
                >
                    Send
                </Button>
            </Box>
        </Paper>
    );
};

export default ChatConsole;
